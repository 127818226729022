import React from 'react';
import styles from './InstructionModal.module.css';

const InstructionModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Welcome to Semioscape.org</h2>
        <p><b>Semioscape is an AI-enabled concept cartographer.</b></p>
        <p>Here's how to use this tool:</p>
        <ul className={styles.instructionList}>
          <li>Use the 🌐, 🪨, and 💭 buttons to expand a word's associations</li>
          <ul>
            <li> 🌐 - Prompt's AI for <b>general</b> associations</li>
            <li> 🪨 - Prompt's AI for <b>concrete</b> associations</li>
            <li> 💭 - Prompt's AI for <b>abstract</b> associations</li>
          </ul>
          <li>❌ - Deletes an association and its children</li>
          <li>Enter a custom word in the input field at the bottom to add new nodes</li>
          <li>Navigation:</li>
          <ul>
            <li>Click on a word to focus</li>
            <li>Click on a node in the graph to highlight its neighbors</li>
            <li>Double-click background of the graph to remove higlighting</li>
            <li>Double-click on a node to expand it</li>
            <li>Drag nodes to rearrange the graph</li>
            <li>Use the mouse wheel to zoom in and out</li>
          </ul>
          <li>You can save and reset your session with the buttons:</li>
          <ul>
            <li>🔄 - To reset</li>
            <li>💾 - To save</li>
            <li>📤 - To upload</li>
          </ul>
        </ul>
        <p>
        For custom input, you can enter words (apple pie), contexual cues (American apple pie), 
        or even questions (When are apple pies eaten?)
        </p>
        <p>
          <i>Built to think with by <a href="https://micha.love" target="_blank">micha.love</a>.</i>
        </p>
        <button onClick={onClose}>Got it!</button>
      </div>
    </div>
  );
};

export default InstructionModal;