import React from 'react';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.siteName}>Semioscape.org</h1>
      <nav className={styles.nav}>
        <a 
          href="https://thelastwave.substack.com/" 
          target="_blank" 
          rel="noopener noreferrer"
          className={styles.link}
        >
          Substack
        </a>
      </nav>
    </header>
  );
};

export default Header;