import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
  ReactGA.initialize('G-8HLMZHJB19'); // Replace 'YOUR_TRACKING_ID' with your actual tracking ID
};

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  // ReactGA.pageview(window.location.pathname);
  ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });

};

export { initializeAnalytics, logPageView };
