import styles from './InteractiveArtifactExplorer.module.css';
import artStyles from './Artifact.module.css';
import D3Graph from './D3Graph';
import './global.css';

import React, { useState, useEffect, useCallback } from 'react';
import { initializeAnalytics, logPageView } from './analytics';
import { Analytics } from '@vercel/analytics/react';
import InstructionModal from './InstructionModal';
import ReactGA from 'react-ga4';

import Header from './Header';
import CircleLoader from "react-spinners/CircleLoader";

const resetData = {
  associations: [
    // {
    //   word: "Curiosity",
    //   explanation: "The strong desire to know or learn; the driving force behind discovery and innovation."
    // },
    // {
    //   word: "Climate Change",
    //   explanation: "Long-term alteration of global weather patterns and temperatures; a phenomenon with far-reaching planetary impacts."
    // },
    // {
    //   word: "Noosphere",
    //   explanation: "The sphere of human thought and mental activity; the domain of collective intelligence and its influence on the world."
    // }
  ]
};
// const stubbedData = {
//   "associations": [
//     {
//       "word": "Obscure",
//       "explanation": "Not well known or easily understood.",
//       "associations": []
//     },
//     {
//       "word": "Hidden",
//       "explanation": "Something that is obscure is often hidden or not easily visible."
//     },
//     {
//       "word": "Mysterious",
//       "explanation": "Obscure things are often mysterious due to their lack of clarity or understanding."
//     }
//   ]
// };

const stubbedData = {
  associations: [
    {
      word: "Concept Cartography",
      explanation: "The practice of visually mapping and connecting ideas and concepts to explore their relationships and generate new insights.",
      associations: [
        {
          word: "Knowledge Mapping",
          explanation: "The process of creating visual representations of knowledge domains to facilitate understanding and discovery.",
          associations: [
            {
              word: "Information Architecture",
              explanation: "The structural design of shared information environments, organizing and labeling content for effective navigation and comprehension."
            },
            {
              word: "Mind Mapping",
              explanation: "A diagram used to visually organize information, typically radiating from a central concept.",
              associations: [
                {
                  word: "Radial Thinking",
                  explanation: "A cognitive process that involves exploring ideas outward from a central point, often used in creative problem-solving."
                },
                {
                  word: "Brainstorming Techniques",
                  explanation: "Various methods used to generate creative ideas and solutions, often employing visual or collaborative approaches."
                },
                {
                  word: "Visual Note-taking",
                  explanation: "The practice of recording information using a combination of words, drawings, and other visual elements."
                }
              ]
            },
            {
              word: "Ontology Design",
              explanation: "The creation of structured vocabularies that represent and categorize entities within a domain and their relationships."
            }
          ]
        },
        {
          word: "Idea Visualization",
          explanation: "The practice of representing thoughts, concepts, and information in visual formats to enhance understanding and communication.",
          associations: [
            {
              word: "Infographics",
              explanation: "Visual representations of information, data, or knowledge designed to present complex information quickly and clearly."
            },
            {
              word: "Data Visualization",
              explanation: "The graphical representation of data and information, often used to reveal patterns, trends, and insights."
            },
            {
              word: "Visual Thinking",
              explanation: "The phenomenon of thinking through visual processing using the part of the brain that is emotional and creative."
            }
          ]
        },
        {
          word: "Semantic Networks",
          explanation: "Graphic representations of knowledge in patterns of interconnected nodes and arcs, representing concepts and their relationships.",
          associations: [
            {
              word: "Natural Language Processing",
              explanation: "A field of AI that focuses on the interaction between computers and humans using natural language."
            },
            {
              word: "Linked Data",
              explanation: "A method of publishing structured data so that it can be interlinked and become more useful through semantic queries."
            },
            {
              word: "Conceptual Relationships",
              explanation: "The logical connections between different ideas or concepts, often revealing deeper meanings and insights.",
              associations: [
                {
                  word: "Knowledge Graphs",
                  explanation: "A knowledge base that uses a graph-structured data model to integrate data and represent relationships between entities."
                },
                {
                  word: "Ontology Engineering",
                  explanation: "The process of designing, implementing and maintaining ontologies, which are formal representations of knowledge."
                },
                {
                  word: "Semantic Web Technologies",
                  explanation: "A set of standards and tools designed to promote common data formats and exchange protocols on the web."
                }
              ]
            }
          ]
        },
        {
          word: "Cognitive Connections",
          explanation: "The mental associations and links formed between different pieces of information in the human mind.",
          associations: [
            {
              word: "Associative Thinking",
              explanation: "A thought process where ideas and concepts are linked through shared attributes or relationships."
            },
            {
              word: "Memory Techniques",
              explanation: "Strategies and methods used to improve one's ability to store and recall information effectively."
            },
            {
              word: "Creative Problem Solving",
              explanation: "The process of redefining problems and opportunities, coming up with novel ideas, and taking action on new solutions."
            }
          ]
        },
        {
          word: "Interdisciplinary Thinking",
          explanation: "An approach that integrates insights from multiple disciplines to gain a more comprehensive understanding of complex issues.",
          associations: [
            {
              word: "Cross-pollination of Ideas",
              explanation: "The process of combining or transferring ideas from one field or context to another, often leading to innovation."
            },
            {
              word: "Systems Thinking",
              explanation: "A holistic approach to analysis that focuses on how system components interrelate and how systems work over time and within larger systems."
            },
            {
              word: "Holistic Approach",
              explanation: "A method of understanding systems by examining the system as a whole, rather than solely focusing on individual components."
            }
          ]
        }
      ]
    }
  ]
};

const AssociationType = {
  NORMAL: '🌐',
  CONCRETE: '🪨',
  ABSTRACT: '💭',
};

export const Artifact = ({id, word, explanation, associations = [], type = '🌐',  depth = 0, onGenerate, onToggle, onDelete, autoExpand = true, parentWord = null, handleWordClick }) => {
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeType, setActiveType] = useState(null);
  const [customInput, setCustomInput] = useState('');
  useEffect(() => {
    if (autoExpand) {
      setExpanded(true);
    }
  }, [autoExpand]);

  useEffect(() => {
    if (associations.length > 0) {
      setExpanded(true);
    }
  }, [associations]);

  const handleToggle = () => {
    setExpanded(!expanded);
    onToggle(word);
  };

  const handleGenerate = async (type, customWord = null) => {
    setActiveType(type);
    setIsLoading(true);
    await onGenerate(customWord || word, type, parentWord);
    setIsLoading(false);
    if (customWord) {
      setCustomInput('');
    }
  };

  const handleCustomSubmit = (e) => {
    e.preventDefault();
    if (customInput.trim()) {
      handleGenerate(AssociationType.NORMAL, customInput.trim());
    }
  };

  return (
    <div id={id} className={`artifact ${artStyles.artifact}`} style={{ marginLeft: `${depth * 20}px` }}>
      <div className={`artifact-content ${artStyles.artifactContent}`}>
        <div className={`artifact-header ${artStyles.artifactHeader} ${getTypeStyle(type)}`} onClick={() => handleWordClick(word)}>
          <span className={`${artStyles.toggleIcon}`} onClick={handleToggle}>
            {associations.length > 0 ? (expanded ? '▼' : '▶') : '•'}
          </span>
          <strong>{word}</strong>
          {Object.values(AssociationType).map((type) => (
            <button 
              key={type}
              onClick={() => handleGenerate(type)} 
              disabled={isLoading}
              className={`${artStyles.associationButton} ${activeType === type ? artStyles.active : ''}`}
              title={getTooltipText(type)}
            >
              {isLoading ? '' : type}
            </button>
          ))}
          <button 
            onClick={() => onDelete(word)} 
            className={`${artStyles.deleteButton}`}
            title="Delete node"
          >
            {isLoading ? '' : '❌'}
          </button>
          <CircleLoader
                loading={isLoading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
        </div>
        {expanded && (
          <p className={`artifact-explanation ${artStyles.explanation}`}>{explanation}</p>
        )}
      </div>
      {expanded && associations.map((assoc, index) => (
        <Artifact
          key={index}
          {...assoc}
          id={`${id}-${index}`}
          depth={depth + 1}
          onGenerate={onGenerate}
          onToggle={onToggle}
          onDelete={onDelete}
          autoExpand={assoc.autoExpand}
          parentWord={word}
          handleWordClick={handleWordClick}
        />
      ))}
    </div>
  );
};

// Add this function to your component
function getTooltipText(associationType) {
  switch (associationType) {
    case '🌐':
      return 'General';
    case '🪨':
      return 'Concrete';
    case '💭':
      return 'Abstract';
    default:
      return '';
  }
}

function getTypeStyle(associationType) {
  switch (associationType) {
    case '🌐':
      return artStyles.general;
    case '🪨':
      return artStyles.concrete;
    case '💭':
      return artStyles.abstract;
    default:
      return '';
  }
}

const InteractiveArtifactExplorer = () => {
  const [networkData, setNetworkData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedNodeId, setFocusedNodeId] = useState(null);
  const [graphMethods, setGraphMethods] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleGraphReady = useCallback((methods) => {
    setGraphMethods(methods);
  }, []);

  const handleWordClick = (word) => {
    if (graphMethods) {
      graphMethods.centerOnNode(word);
    }

    // Track the event
    ReactGA.event({
      category: 'User',
      action: 'Clicked on Word',
      label: word
    });
  };

  const handleCustomSubmit = async (customInput) => {
    setIsLoading(true);
    await generateMoreExamples(customInput, AssociationType.NORMAL);
    setIsLoading(false);
  };

  useEffect(() => {
    const hasSeenInstructions = localStorage.getItem('hasSeenInstructions');
    if (!hasSeenInstructions) {
      setIsModalOpen(true);
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('hasSeenInstructions', 'true');
  };

  const deleteNode = (wordToDelete) => {
    setNetworkData(prevData => {
      const deleteNodeRecursive = (associations) => {
        return associations.filter(assoc => {
          if (assoc.word === wordToDelete) {
            return false; // This node will be deleted
          }
          if (assoc.associations) {
            assoc.associations = deleteNodeRecursive(assoc.associations);
          }
          return true;
        });
      };
  
      return {
        ...prevData,
        associations: deleteNodeRecursive(prevData.associations)
      };
    });
  
    // Update the D3 graph
    if (graphMethods && graphMethods.deleteNode) {
      graphMethods.deleteNode(wordToDelete);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem('networkExplorerData');
    if (savedData) {
      setNetworkData(JSON.parse(savedData));
    } else {
      setNetworkData(stubbedData);
    }
  }, []);

  useEffect(() => {
    if (networkData) {
      localStorage.setItem('networkExplorerData', JSON.stringify(networkData));
    }
  }, [networkData]);

  const generateMoreExamples = async (word, type, parentWord = null) => {
    try {
      const response = await fetch('https://semioscape-backend.vercel.app/api/generate', {
      // const response = await fetch('http://127.0.0.1:5000/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ word: word, type: type, parentWord: parentWord }),
      });

      if (!response.ok) {
        throw new Error('API call failed');
      }

      let newNodeId = null;

      const newData = await response.json();
      setNetworkData(prevData => {
        const updateAssociations = (associations, targetWord, type = '🌀', currentPath = '',) => {
          return associations.map((assoc, index) => {
            const newPath = currentPath ? `${currentPath}-${index}` : `${index}`;
            if (assoc.word.toLowerCase() === targetWord.toLowerCase()) {
              newNodeId = newPath;
              setFocusedNodeId(newPath);
              return {
                ...assoc,
                explanation: newData.definition,
                associations: [
                  ...(assoc.associations || []),
                  ...newData.associations.map(na => ({ 
                    ...na, 
                    associations: [], 
                    autoExpand: true,
                    type: type
                  }))
                ],
                autoExpand: true
              };
            }
            if (assoc.associations && assoc.associations.length > 0) {
              return {
                ...assoc,
                associations: updateAssociations(assoc.associations, targetWord, type, newPath),
                // type: type,
              };
            }
            const clickedNodeId = findNodeId(prevData.associations, word);
            setFocusedNodeId(clickedNodeId);
            return assoc;
          });
        };

        const wordExists = (associations, targetWord) => {
          return associations.some(assoc => 
            assoc.word.toLowerCase() === targetWord.toLowerCase() ||
            (assoc.associations && wordExists(assoc.associations, targetWord))
          );
        };

        if (wordExists(prevData.associations, word)) {
          return {
            associations: updateAssociations(prevData.associations, word, type)
          };
        } else {
          newNodeId = `${prevData.associations.length}`;
          setFocusedNodeId(`${prevData.associations.length}`);
          return {
            associations: [
              ...prevData.associations,
              { 
                word: word, 
                type: type,
                explanation: newData.definition, 
                associations: newData.associations, 
                autoExpand: true 
              }
            ]
          };
        }
      });

      console.log(newNodeId);
      // After updating the network data, scroll to and highlight the new node
      if (newNodeId) {
        setTimeout(() => {
          scrollAndHighlight(newNodeId);
        }, 100); // Small delay to ensure the DOM has updated
      }
    } catch (error) {
      console.error('Error generating examples:', error);
    }
  };

  const scrollAndHighlight = useCallback((nodeId) => {
    const element = document.getElementById(nodeId);
    if (element) {
      // Scroll to the element
      element.scrollIntoView({ behavior: 'smooth', block: 'start' , inline: 'start'});
      
      // Find the content element that contains both header and explanation
      const content = element.querySelector('.artifact-content');
      
      if (content) {
        // Add highlight class to the content
        content.classList.add('artifact-highlight');
  
        // Transition out of the highlight after a delay
        setTimeout(() => {
          content.classList.add('artifact-highlight-fade');
          
          // Remove both classes after the transition is complete
          content.addEventListener('transitionend', () => {
            content.classList.remove('artifact-highlight', 'artifact-highlight-fade');
          }, { once: true });
        }, 2000);
      }
    }
  }, []);

  const findNodeId = (nodes, targetWord, parentId = '') => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const currentId = parentId ? `${parentId}-${i}` : `${i}`;
      if (node.word.toLowerCase() === targetWord.toLowerCase()) {
        return currentId;
      }
      if (node.associations) {
        const childId = findNodeId(node.associations, targetWord, currentId);
        if (childId) return childId;
      }
    }
    return null;
  };

  const handleReset = () => {
    if (window.confirm('Are you sure you want to reset the explorer? This will clear all your current data.')) {
      setNetworkData(resetData);
      localStorage.removeItem('networkExplorerData');
      if (graphMethods && graphMethods.resetGraph) {
        graphMethods.resetGraph();
      }
    }
  };

  const handleExport = () => {
    const dataStr = JSON.stringify(networkData, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = 'network_data.json';

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target.result);
          setNetworkData(importedData);
        } catch (error) {
          console.error('Error parsing imported file:', error);
          alert('Error importing file. Please make sure it\'s a valid JSON file.');
        }
      };
      reader.readAsText(file);
    }
  };

  const handleTextClick = useCallback((nodeId) => {
    const element = document.getElementById(nodeId);
    if (element) {
      // Expand the artifact if it's collapsed
      const toggleIcon = element.querySelector(`.${artStyles.toggleIcon}`);
      if (toggleIcon && toggleIcon.textContent === '▶') {
        toggleIcon.click();
      }
  
      // Scroll to the element
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      
      // Find the content element that contains both header and explanation
      const content = element.querySelector('.artifact-content');
      
      if (content) {
        // Add highlight class to the content
        content.classList.add('artifact-highlight');
  
        // Transition out of the highlight after a delay
        setTimeout(() => {
          content.classList.add('artifact-highlight-fade');
          
          // Remove both classes after the transition is complete
          content.addEventListener('transitionend', () => {
            content.classList.remove('artifact-highlight', 'artifact-highlight-fade');
          }, { once: true });
        }, 2000);
      }
    }
  }, []);

  return (
    <div className={styles.explorerContainer}>
      <InstructionModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <div className={styles.splitScreen}>
        <div className={styles.textGraph}>
          <div className={styles.artifactScrollContainer}>
            <div className={styles.artifactContainer}>
              {networkData && networkData.associations.map((artifact, index) => (
                <Artifact
                  key={index}
                  {...artifact}
                  id={`${index}`}
                  onGenerate={generateMoreExamples}
                  onToggle={() => {}}
                  onDelete={deleteNode}
                  handleWordClick={handleWordClick}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.d3Graph}>
          <D3Graph
            data={networkData} 
            focusedNodeId={focusedNodeId}
            onNodeClick={handleWordClick}
            onReady={handleGraphReady}
            onTextClick={handleTextClick}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={openModal} className={styles.iconButton} title="Help">
        {/* ℹ️ ️️ */}
        🆘
        </button>
        <button onClick={handleReset} className={styles.iconButton} title="Reset Explorer">
          🔄
        </button>
        <button onClick={handleExport} className={styles.iconButton} title="Export Data">
          💾
        </button>
        <label className={styles.iconButton} title="Import Data">
          📤
          <input 
            type="file" 
            onChange={handleImport} 
            accept=".json" 
            style={{ display: 'none' }}
          />
        </label>
        <CustomInputForm onSubmit={handleCustomSubmit} isLoading={isLoading} />
      </div>
    </div>
  );
};

const CustomInputForm = React.memo(({ onSubmit, isLoading }) => {
  const [customInput, setCustomInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customInput.trim()) {
      onSubmit(customInput.trim());
      setCustomInput('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.customInputForm}>
      <input
        type="text"
        value={customInput}
        onChange={(e) => setCustomInput(e.target.value)}
        placeholder="Enter custom word..."
        className={styles.customInput}
      />
      <button type="submit" className={styles.customInputButton} disabled={isLoading}>
        {isLoading ? '' : '🌐'}
        <CircleLoader
        loading={isLoading}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      </button>
    </form>
  );
});

function App() {
  useEffect(() => {
    initializeAnalytics();
    logPageView(); // Log the initial page load

    // const unlisten = Router.history.listen(() => {
    //   logPageView();
    // });
  }, []);

  return (
    <div className="App">
      <Header />
      <InteractiveArtifactExplorer />
      <Analytics />
    </div>
  );
}

export default App;