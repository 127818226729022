import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { Delaunay } from 'd3-delaunay';
import * as d3 from 'd3';

let selectedNode = null;

const TEXT_OFFSET_X = 12; // Adjust this value to change horizontal offset
const TEXT_OFFSET_Y = 4.5;  // Adjust this value to change vertical offset
const VORONOI_MODE = false;  // Adjust this value to change vertical offset

const D3Graph = ({ key, data, focusedNodeId, onNodeClick, onReady, onTextClick }) => {
  const svgRef = useRef();
  const gRef = useRef();
  const zoomRef = useRef();
  const simulationRef = useRef();
  const [isSimulationSettled, setIsSimulationSettled] = useState(false);
  const [wordToIdMap, setWordToIdMap] = useState({});
  const [linkedByIndex, setLinkedByIndex] = useState({});
  const transformRef = useRef(d3.zoomIdentity);


  function colorNodes(d) {
    if (d.type === '💭') {
      return '#f6bc74';
    } else if (d.type === '🪨') {
      return '#6b9c45';
    } else {
      return '#919ef0';
    }
  }
  
  const centerOnNode = useCallback((node) => {
    if (!simulationRef.current) return;

    console.log("Centering on node:", node);
    focusedNodeId = node.id;
    const svg = d3.select(svgRef.current);
    const g = d3.select(gRef.current);
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;
    const scale = 1.5;
  
    // If the simulation hasn't settled, we need to assign a position
    if (typeof node.x === 'undefined' || typeof node.y === 'undefined') {
      node.x = width / 2;
      node.y = height / 2;
    }
  
    const x = width / 2 - node.x * scale;
    const y = height / 2 - node.y * scale;
    
    const t = d3.transition()
        .duration(250)
        .ease(d3.easePolyIn.exponent(2));
    
    // Reset all nodes to default color
    g.selectAll("circle")
    .attr("fill", colorNodes);
    
    // Change color of the focused node
    g.select(`circle[data-id="${node.id}"]`)
      .transition(t)
      .attr("fill", "#8ace00")
      .attr("r", 10);

    g.select(`text[data-id="${node.id}"]`)
      .transition(t)
      .attr("fill", "#8ace00");
  
    g.transition().duration(750)
      .attr("transform", `translate(${x},${y}) scale(${scale})`);
  
    zoomRef.current.transform(svg.transition().duration(750), d3.zoomIdentity.translate(x, y).scale(scale));
    // updateVoronoi(d3.zoomIdentity.translate(x, y).scale(scale));
    // Pin the node for 5 seconds
    node.fx = node.x;
    node.fy = node.y;
    setTimeout(() => {
      const t = d3.transition()
        .duration(250)
        .ease(d3.easePolyIn.exponent(2));
      node.fx = null;
      node.fy = null;
      g.select(`circle[data-id="${node.id}"]`)
        .transition(t)
        // .attr("fill", "#69b3a2")
        .attr("fill", colorNodes)
        .attr("r", 7);
    g.select(`text[data-id="${node.id}"]`)
      .transition(t)
      .attr("fill", "#000");
    }, 5000);
  }, []);

  const graphData = useMemo(() => {
    if (!data || !data.associations) return { nodes: [], links: [] };

    const flattenData = (nodes, parentId = null, depth = 0) => {
      let flattened = [];
      nodes.forEach((node, index) => {
        const id = parentId ? `${parentId}-${index}` : `${index}`;
        flattened.push({ id, word: node.word, parentId, depth, type: node.type });
        if (node.associations) {
          flattened = flattened.concat(flattenData(node.associations, id, depth + 1));
        }
      });
      return flattened;
    };
    
    const flattenedNodes = flattenData(data.associations);

    // Collapse duplicate nodes
    const uniqueNodes = [];
    const wordMap = new Map();

    flattenedNodes.forEach(node => {
      if (!wordMap.has(node.word)) {
        wordMap.set(node.word, node);
        uniqueNodes.push(node);
      } else {
        const existingNode = wordMap.get(node.word);
        // Merge parentId (if it doesn't already exist)
        if (node.parentId && !existingNode.parentIds) {
          existingNode.parentIds = [existingNode.parentId, node.parentId];
        } else if (node.parentId) {
          existingNode.parentIds = existingNode.parentIds || [];
          if (!existingNode.parentIds.includes(node.parentId)) {
            existingNode.parentIds.push(node.parentId);
          }
        }
        // Update depth to the minimum depth
        existingNode.depth = Math.min(existingNode.depth, node.depth);
      }
    });

    // Create links
    const links = [];
    uniqueNodes.forEach(node => {
      const addLink = (parentId) => {
        const parentNode = flattenedNodes.find(n => n.id === parentId);
        if (parentNode && wordMap.has(parentNode.word)) {
          const sourceNode = wordMap.get(parentNode.word);
          links.push({ source: sourceNode.id, target: node.id });
        }
      };

      if (node.parentId) {
        addLink(node.parentId);
      }
      if (node.parentIds) {
        node.parentIds.forEach(addLink);
      }
    });

    const newWordToIdMap = {};
    uniqueNodes.forEach(node => {
      newWordToIdMap[node.word.toLowerCase()] = node.id;
    });
    setWordToIdMap(newWordToIdMap);

    // Initialize linkedByIndex
    const newLinkedByIndex = {};
    links.forEach(d => {
      newLinkedByIndex[d.source + "," + d.target] = 1;
      newLinkedByIndex[d.source + "," + d.source] = 1;
      newLinkedByIndex[d.target + "," + d.target] = 1;
    });
    setLinkedByIndex(newLinkedByIndex);

    return { nodes: uniqueNodes, links };
  }, [data]);

  const updateVoronoi = useCallback((transform) => {
    if (!VORONOI_MODE) return;
    if (!svgRef.current) return;
    if (!transform) return;
    let data = graphData;

    const svg = d3.select(svgRef.current);
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;
    const voronoiGroup = svg.select(".voronoi");

    const delaunay = Delaunay.from(
      data.nodes, 
      d => transform.applyX(d.x), 
      d => transform.applyY(d.y)
    );
    const voronoi = delaunay.voronoi([0, 0, width, height]);

    voronoiGroup.selectAll("path")
      .data(data.nodes)
      .join("path")
      .attr("d", (_, i) => voronoi.renderCell(i))
      .attr("fill", "none")
      .attr("stroke", "grey")
      .attr("stroke-width", 1 / (25 * transform.k))
      .style("pointer-events", "none");
  }, [graphData.nodes]);

  const resetGraph = useCallback(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove();
      simulationRef.current = null;
      setIsSimulationSettled(false);
      transformRef.current = d3.zoomIdentity;
      setWordToIdMap({});
      setLinkedByIndex({});
    }
  }, []);

  // Expose methods to parent component
  useEffect(() => {
    if (onReady && graphData.nodes.length > 0) {
      onReady({
        centerOnNode: (word) => {
          const nodeId = wordToIdMap[word.toLowerCase()];
          if (nodeId) {
            const node = graphData.nodes.find(n => n.id === nodeId);
            if (node) {
              centerOnNode(node);
            }
          }
        },
        getNodeIdByWord: (word) => wordToIdMap[word.toLowerCase()],
        resetGraph: resetGraph
      });
    }
  }, [wordToIdMap, graphData.nodes, centerOnNode, onReady]);

  // const linkedByIndex = {};
  // graphData.links.forEach(function(d) {
  //   var key = d.source + "," + d.target;
  //   linkedByIndex[d.source + "," + d.target] = 1;
  //   linkedByIndex[d.source + "," + d.source] = 1;
  //   return linkedByIndex;
  // });

  // function neighboring(a, b) {
  //   console.log('poo', linkedByIndex);
  //   console.log('ID', a.id, linkedByIndex);
  //   var key_a = a.id + "," + b.id;
  //   var key_b = b.id + "," + a.id;
  //   if (key_a in linkedByIndex) {
  //     console.log('found!');
  //     console.log('result', linkedByIndex[key_a] || linkedByIndex[key_b]);
  //   }
    
  //   // console.log('keya', key_a, 'value', linkedByIndex[key_a]);
  //   // console.log('keyb', key_b, 'value', linkedByIndex[key_b]);
  //   return linkedByIndex[key_a] || linkedByIndex[key_b];
  // } 

  function neighboring(a, b) {
    var key_a = a.id + "," + b.id;
    var key_b = b.id + "," + a.id;
    return linkedByIndex[key_a] || linkedByIndex[key_b];
  }
  
  useEffect(() => {
    if (graphData.nodes.length === 0) {
      // Clear the SVG when there's no data
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove();
      simulationRef.current = null;  // Reset the simulation
      return;
    }
    console.log("Data changed, redrawing graph");

    const svg = d3.select(svgRef.current);
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;

    svg.attr('width', width).attr('height', height);

    // Clear existing content
    svg.selectAll("*").remove();

    // Create a group for the zoomable content
    const g = svg.append("g");
    gRef.current = g.node();

    let currentTransform = d3.zoomIdentity;

    const zoom = d3.zoom()
      .scaleExtent([0.1, 4])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
        transformRef.current = event.transform;
        updateVoronoi(event.transform);
      });

    svg.call(zoom);
    zoomRef.current = zoom;

    const simulation = d3.forceSimulation()
      .force("link", d3.forceLink().id(d => d.id).distance(100))
      .force("charge", d3.forceManyBody().strength(-200))
      .force("collide", d3.forceCollide().radius(20))
      .force("manyBody", d3.forceManyBody().strength(30))
      .force("center", d3.forceCenter(width / 2, height / 2).strength(0.01))
      // .alpha(0.5)
      // .alphaDecay(0.01);
      // .force("center", d3.forceCenter(width / 2, height / 2).strength(0.5));

    simulationRef.current = simulation;

    g.append("g")
      .selectAll("line")
    // For any new edges being added, append a line
    var enterEdges = g.append('g').selectAll('line').enter()
    .append("line").lower()
    .attr("stroke", "#222")
    .attr("stroke-opacity", 0.2)
    .attr("stroke-width", 1)
    .attr("x1", d => d.source.x)
    .attr("y1", d => d.source.y)
    .attr("x2", d => d.target.x)
    .attr("y2", d => d.target.y)

    // For any new nodes being added, append a g
    var enterNodes = g.append('g').selectAll('circle').enter()
      // .attr('transform', d => `translate(${d.x},${d.y})`)
      // .attr('skewX', 70)
      // .attr('skewY', 70)
      .append('circle')
      .attr("stroke", "#FFF") 
      .attr("stroke-width", 0.2)
      .attr("class","node")
      .attr("cx", d => d.x)
      .attr("cy", d => d.y)  
      .attr("r", d => d.r || 5)//make this one of three sizes
      .attr("fill", d => d.color);
      // .call(drag());

    const link = g.append("g")
      .selectAll("line")
      .data(graphData.links)
      .enter().append("line")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 1)
      .attr("stroke-width", 1.3)
      .attr("stroke-linecap", "round")
      .attr("vector-effect", "non-scaling-stroke");

    const node = g.append("g")
      .selectAll("circle")
      .data(graphData.nodes)
      .enter().append("circle")
      .attr("r", 7)
      .attr("fill", colorNodes)
      .attr("stroke", "white")
      .attr("data-id", d => d.id)  // Add this line
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended))
      .on("mouseover", function(event, d) {
        const t = d3.transition()
        .duration(200)
        .ease(d3.easePolyOut.exponent(1));
        d3.select(this)
          .raise() // bring to front
          .transition(t)
          .style("stroke", "black")
          .style("stroke-width", 2)
        d3.select(this).attr("fill", "lightgrey");
      })
      .on("mouseout", function(event, d) {
        const t = d3.transition()
        .duration(200)
        .ease(d3.easePolyOut.exponent(1));
        d3.select(this).interrupt();
        d3.select(this).transition(t).style("stroke", null);
        d3.select(this).attr("fill", colorNodes)
      })
      .on("dblclick", (event, d) => {
        console.log(event);
        event.stopPropagation();
        onNodeClick(d.word);
      });
      
    const text = g.append("g")
      .selectAll("text")
      .data(graphData.nodes)
      .enter().append("text")
      .text(d => d.word)
      .style('font-size', '14px')
      .style('font-family', 'Lato')
      // .style('fill', '#000')
      .attr("data-id", d => d.id)  // Add this line
      .on("click", (event, d) => {
        event.stopPropagation();
        onTextClick(d.id);
      });

    node.append("title")
      .text(d => d.word);

    // Run the simulation for a few ticks to give nodes initial positions
    simulation.tick(10);

    const voronoiGroup = svg.append("g")
      .attr("class", "voronoi");



      node.on('click', function(d, i) {
        selectedNode = d3.select(this);
        // d3.select(this).attr('stroke', '#333').attr('stroke-width', 2);
        var thisId = i.id;
      const t = d3.transition()
        .duration(250)
        .ease(d3.easePolyIn.exponent(2));
        
        d3.select(this)
          .raise() // bring to front
          .transition(t)
          .style("stroke", "black")
          .style("stroke-width", 2)
        // node.select('text').transition().style('opacity', 0)
        
        link.interrupt().transition(t).style("stroke-opacity", function(d) {
          // return neighboring(i,d) ? 1 : 0.1; 
          return (d.source.id == thisId || d.target.id == thisId) ? 1 : 0.1
        });
        text.interrupt().transition(t).style("opacity", function(d) {
          // console.log(neighboring(i,d), i.id, d.id);
          return neighboring(i,d) ? 1 : 0.2;
        });
        node.interrupt().transition(t).style("opacity", function(d) {
          // console.log(neighboring(i,d), i.id, d.id);
          return neighboring(i,d) ? 1 : 0.2;
        });
        d3.select(this).select('text').interrupt().transition(t).style('opacity', '0')

        
      });

      svg.on('dblclick', function(d) {
        if (selectedNode) {
          const t = d3.transition()
          .duration(200)
          .ease(d3.easePolyOut.exponent(1));
          // d3.select(this).attr('stroke', null);
          selectedNode.select('circle').interrupt();
          selectedNode.select('circle').transition(t).style("stroke", null);
          selectedNode.select('text').transition(t).style('opacity', '0');
          text.transition(t).style('opacity', 1)
          node.transition(t).style('opacity', 1)
          link.interrupt().transition(t).style("stroke-opacity", 0.6);
          link.style("fill", "black");
          selectedNode = null;
        }
      });

      simulation
      .nodes(graphData.nodes)
      .on("tick", ticked)
      .on("end", () => {
        console.log("Simulation settled", focusedNodeId);
        setIsSimulationSettled(true);
        
        // If there's a focusedNodeId, center on it after the simulation has settled
        if (focusedNodeId) {
          const focusedNode = graphData.nodes.find(n => n.id === focusedNodeId);
          if (focusedNode) {
            centerOnNode(focusedNode);
            focusedNodeId = null;
          }
        }
      });

    simulation.force("link")
      .links(graphData.links);

      // function updateVoronoi(transform = currentTransform) {
      //   console.log(transform);
      //   console.trace();
      //   let data = graphData;
      //   const delaunay = Delaunay.from(
      //     data.nodes, 
      //     d => transform.applyX(d.x), 
      //     d => transform.applyY(d.y)
      //   );
      //   const voronoi = delaunay.voronoi([0, 0, width, height]);
  
      //   voronoiGroup.selectAll("path")
      //     .data(data.nodes)
      //     .join("path")
      //     .attr("d", (_, i) => voronoi.renderCell(i))
      //     .attr("fill", "none")
      //     .attr("stroke", "#2ca02c")
      //     .attr("stroke-width", 1 / (25*transform.k))
      //     .style("pointer-events", "all")
      //     .on("mouseover", (event, d) => {
      //       d3.select(event.target).attr("fill", "rgba(44, 160, 44, 0.2)");
      //     })
      //     .on("mouseout", (event) => {
      //       d3.select(event.target).attr("fill", "none");
      //     })
      //     .on("click", (event, d) => {
      //       onNodeClick(d.word);
      //     })
      //     .style("pointer-events", "none");
      // }

    function ticked() {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
  
      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
  
      text
        .attr("x", d => d.x + TEXT_OFFSET_X)
        .attr("y", d => d.y + TEXT_OFFSET_Y);

        // Update Voronoi cells
      // console.log('ticked', currentTransform);
      updateVoronoi(transformRef.current);
      // Center on the focused node during each tick
      if (focusedNodeId) {
        const focusedNode = graphData.nodes.find(n => n.id === focusedNodeId);
        if (focusedNode) {
          const x = width / 2 - focusedNode.x * currentTransform.k;
          const y = height / 2 - focusedNode.y * currentTransform.k;
          g.attr("transform", `translate(${x},${y}) scale(${currentTransform.k})`);
          zoomRef.current.transform(svg, d3.zoomIdentity.translate(x, y).scale(currentTransform.k));
          // zoomRef.current.transform(svg.transition().duration(750), d3.zoomIdentity.translate(x, y).scale(currentTransform.k));
        }
      }
      
    }

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
      updateVoronoi();
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
      updateVoronoi();
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
      updateVoronoi();
    }

    // Add double-click to zoom
    svg.on("dblclick.zoom", null);

    svg.append('defs')
      .append('style')
      .attr('type', 'text/css')
      .text("@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap');");


  }, [graphData, onNodeClick, centerOnNode, wordToIdMap, onTextClick]);

  useEffect(() => {
    console.log("focusedNodeId changed:", focusedNodeId);
    if (focusedNodeId && simulationRef.current) {
      const nodes = simulationRef.current.nodes();
      const focusedNode = nodes.find(n => n.id === focusedNodeId);
      console.log("Found focusedNode:", focusedNode);
      if (focusedNode) {
        if (!isSimulationSettled) {
          simulationRef.current.alpha(1).restart();
        }
      }
    }
  }, [focusedNodeId, isSimulationSettled]);

  return <svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>;
};



export default D3Graph;